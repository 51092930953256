import React, { useState, useEffect, useCallback } from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { ChevronRight, Circle, Folder } from "react-feather"
import SideMenuGroup from "./SideMenuGroup"
import classnames from "classnames"
import _ from "lodash"

import { Link } from "react-router-dom"
import { history } from "../../../../../history"

const SideMenuContent = (props) => {
  // Get all modules information object from localStorage
  const MODULES = JSON.parse(localStorage.getItem("MODULES"))

  // Get current page url
  const activePath = props.activePath

  // Store searched module here
  let matchesModule = "No module has been matches"

  // Declare array for storing parent code (for level 2 menu)
  let parentArr = []

  // Create callback to set a default position for sidebar scroll
  const scrollToRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView()
    }
  }, [])

  // Function to find module object for current menu.
  // It search through all the module (both level 1 and level 2) by comparing url path to find which level 0 module contain current menu.
  // When the current menu is found, the level 0 module will be returned.
  // If current menu is level 2 module, it will also returned level 1 menu code.
  const findCurrentMenuInfo = (pathname, modules) => {
    let level2ParentCode = null

    if (modules) {
      const matchedModules = modules.find((module) => {
        const subModulesAfterFilter = module.level1.find((subModule) => {
          if (subModule.level2.length > 0) {
            const subModuleLv2AfterFilter = subModule.level2.find((subModuleLv2) => subModuleLv2.menuPath === pathname)

            if (subModuleLv2AfterFilter) {
              level2ParentCode = subModuleLv2AfterFilter.menuParentCode
              return true
            }
          }

          return subModule.menuPath === pathname
        })

        if (subModulesAfterFilter) {
          return true
        }
      })

      if (matchedModules) {
        return {
          menuObj: matchedModules,
          level2ParentCode: level2ParentCode
        }
      }
    }
    return "No module has been matches"
  }

  // Below function and variable is for determining which menu wll be active when the page is loaded and when user is clicked on the sidebar
  //// Find current module object (for all menu) and parent menu code (for level 2 menu only)
  const currentMenuInfo = findCurrentMenuInfo(activePath, MODULES)
  if (_.isObject(currentMenuInfo)) {
    matchesModule = currentMenuInfo.menuObj
    parentArr = [currentMenuInfo.level2ParentCode]
  }

  //// List of prepared side bar menu items are stored here
  const [sideMenuItemList, setSideMenuItemList] = useState([])
  //// Dom elements of the side bar menu items are stored here
  const [sideMenu, setSideMenu] = useState([])
  //// Collapsible menus state are stored here
  const [groupState, setGroupState] = useState({
    flag: true,
    isHovered: false,
    activeGroups: [],
    tempArr: []
  })
  //// Declare function to be used in the SideMenuGroup
  const redirectUnauthorized = () => {
    history.push("/misc/not-authorized")
  }
  //// To prepare groupState when the page is render
  const initRender = (parentArr) => {
    setGroupState({
      ...groupState,
      activeGroups: parentArr,
      flag: false
    })
  }

  //// Determine which collapsible menus will be active/deactive when user clicking on sidebar menu
  const handleGroupClick = (id, parent = null, type = "") => {
    let open_group = groupState.activeGroups
    let temp_arr = groupState.tempArr

    if (type === "item") {
      temp_arr = [parent]
      if (parent !== null) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
      }
      open_group = temp_arr
    } else if (type === "collapse") {
      if (!open_group.includes(id)) {
        open_group.push(id)
      } else {
        open_group.splice(open_group.indexOf(id), 1)
      }

      if (parent === null) {
        temp_arr = [id]
      } else {
        if (open_group.includes(parent)) {
          temp_arr = open_group
        }
        if (temp_arr.includes(id)) {
          temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
        } else {
          temp_arr.push(id)
        }
      }
    } else {
      temp_arr = []
    }

    setGroupState({
      ...groupState,
      activeGroups: open_group,
      tempArr: temp_arr
    })
  }

  // Menu rendering function
  // This function create children array to be attached to the level 1 menu
  const mapChild = (child) =>
    child.map((item) => {
      return {
        id: item.menuCode,
        title: item.menuNameTH,
        type: "item",
        navLink: item.menuPath,
        icon: <Circle size={10} />
      }
    })

  // Prepare lists of sidebar menu item to use in the sidebar rendering function
  useEffect(() => {
    initRender(parentArr[0] ? [parentArr[0]] : [])
    if (_.isObject(matchesModule) && matchesModule.level1.length > 0) {
      const tempMenuItem = []
      matchesModule.level1.forEach((item) => {
        tempMenuItem.push({
          id: item.menuCode,
          title: item.menuNameTH,
          type: item.level2.length > 0 ? "collapse" : "item",
          icon: item.icon ? (
            <i className={`mr-1 ${item.icon}`}></i>
          ) : item.level2.length > 0 ? (
            <Folder size={15} />
          ) : (
            <Circle size={10} />
          ),
          navLink: item.menuPath,
          badge: "primary",
          children: mapChild(item.level2)
        })
      })
      setSideMenuItemList(tempMenuItem)
    } else {
      console.log(matchesModule)
    }
  }, [])

  // Sidebar rendering function
  useEffect(() => {
    const tempSideMenu = sideMenuItemList.map((item) => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link

      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open: groupState.activeGroups.includes(item.id),
            "sidebar-group-active": groupState.activeGroups.includes(item.id),
            hover: groupState.activeGroups.includes(item.id),
            active: activePath == item.navLink && item.type === "item",
            disabled: item.disabled
          })}
          ref={activePath == item.navLink ? scrollToRef : null}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation()
            if (item.type === "item") {
              props.handleActiveItem(item.navLink)
              handleGroupClick(item.id, null, item.type)
              if (props.deviceWidth <= 1200 && item.type === "item") {
                props.toggleMenu()
              }
            } else {
              handleGroupClick(item.id, null, item.type)
            }
          }}
        >
          <CustomAnchorTag
            to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink : ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${item.badgeText ? "justify-content-between" : "justify-content-start"}`}
            onMouseEnter={() => {
              props.handleSidebarMouseEnter(item.id)
            }}
            onMouseLeave={() => {
              props.handleSidebarMouseEnter(item.id)
            }}
            key={item.id}
            onClick={(e) => {
              return item.type === "collapse" ? e.preventDefault() : ""
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text" id={`menu-${item.id}`}>
              {item.icon}
              <span className="menu-item menu-title">{item.title}</span>
              <UncontrolledTooltip placement="right" target={`menu-${item.id}`}>
                {item.title}
              </UncontrolledTooltip>
            </div>

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === "collapse" ? <ChevronRight className="menu-toggle-icon" size={13} /> : ""}
          </CustomAnchorTag>
          {item.type === "collapse" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={handleGroupClick}
              activeGroup={groupState.activeGroups}
              handleActiveItem={props.handleActiveItem}
              activeItemState={props.activeItemState}
              handleSidebarMouseEnter={props.handleSidebarMouseEnter}
              activePath={activePath}
              initRender={initRender}
              parentArr={parentArr}
              triggerActive={undefined}
              currentActiveGroup={groupState.activeGroups}
              permission={props.permission}
              currentUser={props.currentUser}
              redirectUnauthorized={redirectUnauthorized}
              collapsedMenuPaths={props.collapsedMenuPaths}
              toggleMenu={props.toggleMenu}
              deviceWidth={props.deviceWidth}
              scrollToRef={scrollToRef}
            />
          ) : (
            ""
          )}
        </li>
      )

      if (item.navLink && item.collapsed !== undefined && item.collapsed === true) {
        props.collapsedPath(item.navLink)
        props.collapsedMenuPaths(item.navLink)
      }

      return renderItem
    })

    setSideMenu(tempSideMenu)
  }, [sideMenuItemList, groupState])

  return <React.Fragment>{sideMenu}</React.Fragment>
}

export default SideMenuContent
