import React, { useState } from "react"
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Media } from "reactstrap"
import { useLocation } from "react-router-dom"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Button } from "../../../components/custom/button/Button"
import { AlertNotifications } from "../../../components/custom/notifications/Notifications"
import { Spinner } from "../../../components/custom/spinner/Spinner"
import _ from "lodash"
import styled from "styled-components"
import { aesDecrypt } from "../../../helper/displayHelper.jsx"

export const PerfectscrollbarStyled = styled.span`
  .custom-perfectscrollbar {
    overflow: auto !important;
    .ps__rail-x,
    .ps__rail-y {
      display: none !important;
      .ps__thumb-y {
        display: none !important;
      }
    }
  }

  .custom-perfectscrollbar::-webkit-scrollbar {
    width: 8px;
    background: #fff;
  }

  /* Track */
  .custom-perfectscrollbar::-webkit-scrollbar-track:hover {
    background: #ddd;
  }

  /* Handle */
  .custom-perfectscrollbar::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 20px;
  }

  /* Handle on hover */
  .custom-perfectscrollbar::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
`

const GetModule = () => {
  const MODULES = JSON.parse(localStorage.getItem("MODULES"))
  let location = useLocation().pathname
  const consentMessage = `สำนักการศึกษา มีความมุ่งมั่นในการดำเนินการด้านการคุ้มครองข้อมูลส่วนบุคคลให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 และกฎหมายที่เกี่ยวข้อง โดยจะทำการเก็บประวัติผู้ใช้งานระบบฯ เพื่อให้การปฏิบัติงาน เป็นไปตามกฎหมาย และมาตรฐานสากลในการคุ้มครองข้อมูลส่วนบุคคล รวมถึงความคุ้มครองข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล และมาตรการในการบริหารจัดการ การละเมิดสิทธิของเจ้าของข้อมูลส่วนบุคคลที่มีประสิทธิภาพและเหมาะสม`
  const [alert, setAlert] = useState({
    showAlert: false,
    typeAlert: "confirm",
    propstitle: "ความยินยอมเรื่องข้อมูลส่วนบุคคล",
    propscontent: consentMessage,
    confirmBtnText: "ยินยอม",
    cancelBtnText: "ไม่ยินยอม",
    nextFnc: () => {},
    closeFnc: () => {
      setAlert({ ...alert, showAlert: false })
    }
  })
  const findCurrentMenuCode = (pathname, modules) => {
    if (modules) {
      const matchedModules = modules.find((module) => {
        const subModulesAfterFilter = module.level1.find((subModule) => {
          if (subModule.level2.length > 0) {
            const subModuleLv2AfterFilter = subModule.level2.find((subModuleLv2) => subModuleLv2.menuPath === pathname)

            if (subModuleLv2AfterFilter) {
              return true
            }
          }

          return subModule.menuPath === pathname
        })

        if (subModulesAfterFilter) {
          return true
        }
      })

      if (matchedModules) {
        return matchedModules.menuCode
      }
    }
    return null
  }

  const currentMenuCode = findCurrentMenuCode(location, MODULES)

  function handleClick(path) {
    window.location = path
  }
  const getModules = () => {
    return (
      <>
        {!MODULES ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          MODULES.map((item, key) => (
            <div
              className={`d-flex justify-content-between body-list-modules ${
                currentMenuCode === item.menuCode ? "active" : ""
              }`}
              key={key}
              onClick={() => {
                if (localStorage.getItem("CONSENT_PERSONAL")) {
                  handleClick(item.firstRoleMenu.menuPath)
                } else {
                  setAlert({
                    ...alert,
                    showAlert: true,
                    nextFnc: () => {
                      localStorage.setItem("CONSENT_PERSONAL", true)
                      handleClick(item.firstRoleMenu.menuPath)
                    }
                  })
                }
              }}
            >
              <Media className="d-flex align-items-start">
                <Media left href="">
                  <div style={{ fontSize: 20, color: "#267659" }}>
                    <i className={item.icon} />
                  </div>
                </Media>
                <Media body>
                  <Media heading className="media-heading mb-0" style={{ color: "#267659" }} tag="h5">
                    {item.menuNameTH}
                  </Media>
                </Media>
              </Media>
            </div>
          ))
        )}
      </>
    )
  }

  let labelLoginDistrict = "สำนักการศึกษา"
  if (localStorage.getItem("USER_TYPE") === "4") {
    labelLoginDistrict =
      localStorage.getItem("USER_DISTRICT_CODE") && localStorage.getItem("USER_DISTRICT_CODE") !== "null"
        ? "สำนักงานเขต" + aesDecrypt(localStorage.getItem("USER_DISTRICT_NAME"))
        : ""
  }

  return (
    <>
      <AlertNotifications {...alert} />
      {labelLoginDistrict && <p className="label-login-district">{labelLoginDistrict}</p>}
      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item d-lg-block">
        <DropdownToggle tag="a" className="nav-link nav-link-label pt-0 pb-0 pl-0 pr-0 ml-1">
          <Button
            type="primary"
            height="40"
            width="150"
            onClick={() => {
              setTimeout(() => {
                // document.querySelectorAll(".ps__rail-y")[0].scroll(20, 100)
                var el = document.querySelector(".ps__rail-y")
                el.dispatchEvent(new Event("scroll"))
              }, 1000)
            }}
          >
            เมนูหลัก
          </Button>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-media dropdown-menu-left pt-0 mt-1">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h4 className="text-white">เมนูหลัก</h4>
            </div>
          </li>
          <PerfectscrollbarStyled>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative custom-perfectscrollbar"
              options={{
                wheelPropagation: false
              }}
            >
              {getModules()}
            </PerfectScrollbar>
          </PerfectscrollbarStyled>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default GetModule
