const CryptoJS = require("crypto-js")

export const aesEncrypt = (str) => {
  if (str) {
    return CryptoJS.AES.encrypt(String(str), "BEMIS_REPORT_SYSTYEM#123").toString()
  }
  return ""
}

export const aesDecrypt = (str) => {
  if (str) {
    const bytes = CryptoJS.AES.decrypt(String(str), "BEMIS_REPORT_SYSTYEM#123")
    return bytes.toString(CryptoJS.enc.Utf8)
  }
  return ""
}

export const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16)
  })
}

export const decodeQueryParam = (p) => {
  return decodeURIComponent(p)
}

export const routeSegment = (number, path) => {
  if (!number) return window.location.pathname
  const pathname = path ? path : window.location.pathname
  if (pathname) {
    const route_path_arr = pathname.split("/").filter((item, i, arr) => item && arr.indexOf(item) === i)
    return route_path_arr[number - 1]
  }
}
