import React, { Component } from "react"
import { NavLink } from "react-router-dom"
class SidebarHeader extends Component {
  render() {
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item m-auto">
            <NavLink to="/" className="navbar-brand"></NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default SidebarHeader
